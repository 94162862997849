
































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiFormLabel from './UiFormLabel.vue';
import UiLoading from './UiLoading.vue';

@Component({ components: { UiFormLabel, UiLoading } })
export default class UiTextEdit extends Vue {
  @Prop()
  public readonly value?: string | number;

  @Prop()
  public readonly name!: string;

  @Prop()
  public readonly description?: string;

  @Prop()
  public readonly label!: string;

  @Prop()
  public readonly placeholder?: string;

  @Prop({ default: 'text' })
  public readonly type!: 'text' | 'email' | 'number';

  @Prop()
  public readonly min?: number;

  @Prop()
  public readonly max?: number;

  @Prop()
  public readonly disabled?: boolean;

  @Prop()
  public readonly icon?: string;

  @Prop()
  public readonly image?: string;

  @Prop({ default: 'left' })
  public readonly iconPosition!: 'left' | 'right';

  @Prop({ default: true })
  public readonly isValid?: boolean;

  @Prop()
  public readonly showValidationIcon?: boolean;

  @Prop()
  public readonly validationMessage?: string;

  @Prop()
  public readonly showValidMark?: boolean;

  @Prop()
  public readonly loading?: boolean;

  @Prop()
  public readonly canClear?: boolean;

  @Prop({ default: true }) // defaulted to true to avoid breaking existing code
  public readonly validated?: boolean;

  @Prop({ default: false })
  public readonly required?: boolean;

  @Prop()
  public readonly inputClass?: string;

  public touched = false;

  public get isDirty() {
    return !!this.value || this.touched || this.validated;
  }

  handleFocus() {
    this.touched = true;
  }

  public mounted() {
    (this.$refs.root as Element).addEventListener('focusout', this.handleFocus);
  }

  public beforeDestroy() {
    (this.$refs.root as Element).removeEventListener('focusout', this.handleFocus);
  }

  public clear(event: MouseEvent) {
    this.$emit('input', '');
    this.$emit('clear');
    (event.currentTarget as HTMLElement)?.blur();
  }

  public onKeyUp(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.$emit('enterKey', this.value);
    }
  }
}
